.homeVideoContent {
    width: 100%;
    height: 100%;
    background-color: #f7b500;
    position: absolute;
    object-fit: unset;
    z-index: 10000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.coinBlastReward {
    width: 100%;
    height: auto;
    max-height: 100%;
    position: absolute;
    object-fit: contain;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

/* On screens that are 600px wide or less, make the columns stack on top of each other instead of next to each other */
@media screen and (max-width: 360px) {
    .coinBlastReward{
        min-width: 280px;
        max-width: 320px;
        top: 40px;
        margin: 0 auto;
    }
}
@media screen and (max-width: 319px) {
    .coinBlastReward{
        top: 50px;
    }
}