.refreshing-indicator {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(51, 51, 51, 0.9);
  padding: 10px;
  z-index: 1000;
}

.spinner {
  border: 4px solid #f3f3f3; 
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.leaderboard-container {
  color: white;
  padding: 20px;
  text-align: center;
  font-family: Arial, sans-serif;
  border-radius: 10px;
  max-width: 600px;
  margin: 20px auto;
}

.leaderboard-title {
  color: #FFD700;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.leaderboard-subtitle {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 5px;
}

.leaderboard-description {
  font-size: 16px;
  opacity: 0.8;
  margin-bottom: 20px;
}

.leaderboard-top-three {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 30px;
  height: 220px;
}

.leaderboard-avatar-container {
  text-align: center;
  width: 30%;
  position: relative;
}

.leaderboard-avatar-container.top-1 {
  order: 2;
  transform: scale(1.2);
  z-index: 3;
}

.leaderboard-avatar-container.top-2 {
  order: 1;
  transform: scale(0.9);
  z-index: 2;
  margin-right: 5px;
}

.leaderboard-avatar-container.top-3 {
  order: 3;
  transform: scale(0.8);
  z-index: 1;
}

.leaderboard-avatar-wrapper {
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 50%;
  margin: 0 auto 10px;
  background: beige;
  position: relative;
}

.leaderboard-rank-badge {
  position: fixed;
  top: -10px;
  left: 0px;
  width: 30px;
  height: 30px;
  background-color: #FFD700;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
  color: #1E1E1E;
  z-index: 4;
}

.leaderboard-avatar-inner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform-origin: center;
  overflow: hidden;
}

.leaderboard-avatar-inner svg {
  width: 98px;
}

.leaderboard-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.leaderboard-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.leaderboard-rank {
  font-size: 18px;
  font-weight: bold;
  margin-right: 10px;
  width: 30px;
}

.leaderboard-name {
  flex: 1;
  text-align: left;
}

.top-3 .leaderboard-name {
  text-align: center;
}

.top-2 .leaderboard-name {
  text-align: center;
}

.top-1 .leaderboard-name {
  text-align: center;
}

.leaderboard-score {
  font-weight: bold;
  margin: 0 10px;
}

.leaderboard-rewards {
  font-size: 14px;
  opacity: 0.8;
}

.leaderboard-item .leaderboard-score-container {
  display: flex;
  justify-content: space-between;
  min-width: 80px;
}

.leaderboard-score-container img {
  width: 22px;
  height: 22px;
}