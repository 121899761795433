.mainSection {
    height:100vh;
    width: 100%;
    min-width: 280px;
    /* max-width: 400px; */
    margin: 0 auto;
    box-sizing: border-box;
    position: relative;
    background-color: #f8b600;
    /* overflow-y: scroll; */
    /* margin-bottom: 100px; */
    /* overflow: hidden; */
    /* overflow-y: none; */
}

.dispNone {
    visibility: hidden
}

.dispNone2 {
    visibility: hidden;
}

.topSection {
    padding-top: 70px;
    text-align: center;
    height: 90px;

}

.topSectionH1 {
    font-family: "rifficBold" !important;
    letter-spacing: 1px;
    font-size: 44px;
    color: white;
}

.topSectionP {
    font-family: "rifficBold" !important;
    letter-spacing: 1px;
    font-size: 18px;
}

.midSection {
    text-align: center;

}

.rewardsToken {
    width: 150px;
    height: 150px;
    margin: 10px 0;
    box-shadow: 0px 4px 2px 0 #b66a2a;
    border-radius: 50%;
    visibility: hidden;

}

/* .slideDown { */
    /* display:none; */
/* } */

.rewardValue {
    font-family: "rifficBold" !important;
    letter-spacing: 1px;
    margin-bottom: -10px;
    font-size: 55px;
    height: 60px;
    color: white;
}

.rewardValueText {
    font-family: "rifficBold" !important;
    letter-spacing: 1px;
    color: white;
    font-size: 35px;
    height: 40px;

}

.score {
    font-family: "rifficBold" !important;
    letter-spacing: 1px;
    font-size: 24px;
    height: 30px;
    position: fixed;
    top: 50%;
    left: 0;
    right: 0;
    z-index: 999;
}

.buttonsSection {
    display: flex;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 15px;
    min-width: 280px;
    width: 100%;
    height: 100px;
    gap: 1em;
    position: fixed;
    top: 70%;
    z-index: 9999;
}

.nextLevelBtn{
    background-color: #3d3c3a;
    height: 50px;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 0px;
    margin-bottom: 0px;
    margin-bottom: 0;
    padding: 15px;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-family: "rifficBold";
    font-size: 18px;
    box-shadow: 0px 4px 0 0 #b66a2a;
    border: none;
    margin: 15px 0;
    position: relative;
    z-index: 2;
}
.backBtn {
    background-color: #3d3c3a;
    height: 50px;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 0px;
    margin-bottom: 0px;
    margin-bottom: 0;
    padding: 15px;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-family: "rifficBold";
    font-size: 18px;
    box-shadow: 0px 4px 0 0 #b66a2a;
    border: none;
    position: relative;
    z-index: 9999;
    width: 75%;
}

.nextLevelBtn:active,
.backBtn:active {

    transform: translateY(3px);
    box-shadow: none;

}


.zoomIn {
    -webkit-animation-name: zoomIn;
    animation-name: zoomIn;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@-webkit-keyframes zoomIn {
    0% {
        opacity: 0;
        -webkit-transform: scale3d(.3, .3, .3);
        transform: scale3d(.3, .3, .3);
    }

    50% {
        opacity: 1;
    }
}

@keyframes zoomIn {
    0% {
        opacity: 0;
        -webkit-transform: scale3d(.3, .3, .3);
        transform: scale3d(.3, .3, .3);
    }

    50% {
        opacity: 1;
    }
}

.slideInDown {
    /* margin-top: 6rem; */
    -webkit-animation-name: slideInDown;
    color: white;
    animation-name: slideInDown;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    position: fixed;
    top: 55%;
    left: 0;
    right: 0;
    z-index: 999;
}

@-webkit-keyframes slideInDown {
    0% {
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
        visibility: visible;
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes slideInDown {
    0% {
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
        visibility: visible;
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}


@media (min-width: 900px) {
    .slideInDown {
        top: 65%;
    }
    .score {
        top: 60%
    }
    .buttonsSection {
        top: 80%;
    }
}