.shareSection{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    /* width: 95%; */
    background: #252525;
    height: 150px;
    display: flex;
    justify-content: center;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    margin: 0 auto;
    padding: 15px 0;
}
.ulShare{
    display: flex;
    width: 100%;
    justify-content: flex-start;
    list-style: none;
    padding-top: 5px;
    padding-left: 0;
    overflow-x: scroll;
}
.liShare{
    margin: 0 10px;
    align-self: flex-start;
    flex-basis: 100%;
}
.shareImage{
    width: 50px;
    height: 50px;
    cursor: pointer;
    border-radius: 50%;
    transition: .2s ease;
}
.sharetext{
    color: white;
    font-family: outfit-regular;
    font-size: 10px;
    text-align: center;
    margin-top: 3px;
}
.shareImage:active{
    box-shadow: 0 0 5px 3px gray;
}