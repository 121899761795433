.spin-wheel-page {
    background-color: #A5FFFB;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    position: relative;
    overflow: hidden;
}

.blur {
    position: absolute;
    border-radius: 50%;
    filter: blur(50px);
    opacity: 0.3;
}

.blur-1 {
    background-color: #0A5E5B;
    top: 10%;
    left: 5%;
    width: 150px;
    height: 150px;
}

.blur-2 {
    background-color: #D36D33;
    top: 30%;
    right: 10%;
    width: 180px;
    height: 180px;
}

.blur-3 {
    background-color: #FE8500;
    bottom: 20%;
    left: 15%;
    width: 130px;
    height: 130px;
}

.blur-4 {
    background-color: #FE8500;
    top: 50%;
    left: 50%;
    width: 160px;
    height: 160px;
}

.blur-5 {
    background-color: #D36D33;
    bottom: 10%;
    right: 20%;
    width: 140px;
    height: 140px;
}

.blur-6 {
    background-color: #0A5E5B;
    top: 5%;
    right: 30%;
    width: 170px;
    height: 170px;
}

.blur-7 {
    background-color: #FE8500;
    bottom: 30%;
    left: 40%;
    width: 190px;
    height: 190px;
}

.wheel-container {
    position: relative;
    width: 300px;
    height: 300px;
    margin: 0 auto 30px;
}

.wheel {
    width: 100%;
    height: 100%;
}

.pointer {
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 30px solid #FFD700;
}

.spin-button {
    background-color: #FFD700;
    color: #333;
    border: none;
    padding: 15px 40px;
    font-size: 20px;
    font-weight: bold;
    border-radius: 30px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.spin-button:hover {
    background-color: #FFC700;
}

.coin {
    position: absolute;
    width: 40px;
    height: 40px;
}

.coin-1 {
    top: 20%;
    right: 15%;
}

.coin-2 {
    bottom: 10%;
    left: 10%;
}

.coin-3 {
    top: 60%;
    left: 3%;
}

.wheel {
    transition: transform 4.8s cubic-bezier(0.25, 0.1, 0.25, 1);
}