.homeContainer {
    position: absolute;
    top: 50;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: black;
    /* z-index: -1; */
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    overflow: auto;
     /*height: 100vh;*/
    height: calc(100vh - 38px);
    scroll-snap-type: y mandatory;
    -ms-overflow-style: none;
    scrollbar-width: none; /* For Firefox */
    /* scrollbar-color: transparent transparent; For Chrome, Edge, and Safari */
}

.homeContainer::-webkit-scrollbar {
    display: none;
  }
  
  /* For Chrome, Edge, and Safari */
  .homeContainer::-webkit-scrollbar {
    width: 6px;
  }
  
  .homeContainer::-webkit-scrollbar-track {
    background-color: transparent;
  }
  
  .homeContainer::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
.videoCard_player{
    position: relative;
    width: 100%;
    height: 100%;
    /*height: calc(100vh - 48px);*/
    /* padding-bottom: 45px;*/
    scroll-snap-align: start;
    scroll-snap-stop: always;
    overflow: hidden;
}
.homeVideoContent {
    width: 100%;
    height: 100%;
    /*height: calc(100vh - 48px);*/
    object-fit: fill;
    /* object-fit: unset; */

    /*object-fit: fill;*/
    /*position: absolute;*/
    /*top: 0;*/
    /*bottom: 0;*/
    /*right: 0;*/
    /*left: 0;*/
}
.welcomeDingWrapper{
    position: fixed;
    top: 38%;
    padding: 15px 0;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    left: 0;
    right: 0;
    background: #1c212acc;
    transition: .5s ease;
    opacity: 1;
    z-index: 1;
  }
  .fadeOut{
    opacity: 0;
    transition: opacity 0.5s ease-out;
}

.playBtnDiv{
    position: absolute;
    z-index: 9;
    width: 40px;
    height: 40px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    background: #41414199;
    border-radius: 50%;
}
#playBtnSvg{
    position: absolute;
    z-index: 9;
    width: 25px;
    height: 25px;
    top: 50%;
    left: 55%;
    transform: translate(-50%,-50%);
}
#playBtnSvg path{
    fill: white;
}
.playText{
    position: relative;
    text-align: center;
    z-index: 9;
    width: 50px;
    height: 50px;
    color: white;
    align-self: end;
    /* text-shadow: 0px 4px 5px #000, 0 0 5px #000; */
    text-shadow:  0px 3px 0px #db7119, 0 4px 0px #fff, 0 0 30px #fff0, 0 0 40px #ff00de00, 0 0 70px #ff00de00, 0 0 80px #ff00de00, 0 0 100px #ff00de00, 0 0 150px #ff00de00;
    letter-spacing: 1.2px;
}
.videoDescBlock{
    position: fixed;
    bottom: 50px;
    left: 15px;
    max-width: 150px;
    text-align: left;
    z-index: 2;
}
.videoDescText{
    color:white;
    font-size: 14px;
    font-family: outfitRegular;
    font-weight: 300;
    letter-spacing: 0.5px;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    text-shadow: 0px 4px 5px #000, 0 0 5px #000
}
#drawer {
    transition: 300ms left;
    -webkit-transition: 300ms left;
    -moz-transition: 300ms left;
    -o-transition: 300ms left;
    position: fixed;
    z-index: 8;
    width: 100%;
    height: 100%;
    /* left: 0; */
    top: 0;
    right: 0;
    background: #FFCA0A;
    transition: 300ms left;
    
}
.slideOutDown {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    height: 150px;
    display: flex;
    justify-content: center;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    margin: 0 auto;
    padding: 15px;
    -webkit-animation-name: slideOutDown;
    animation-name: slideOutDown;
    -webkit-animation-duration: .2s;
    animation-duration: .2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    visibility: hidden;
}
@-webkit-keyframes slideOutDown {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    100% {
        visibility: hidden;
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
    }
}
@keyframes slideOutDown {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    100% {
        visibility: hidden;
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
    }
}
.slideInUp {
    position: fixed;
    z-index: 3;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    height: 150px;
    display: flex;
    justify-content: center;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    margin: 0 auto;
    padding: 15px;
    -webkit-animation-name: slideInUp;
    animation-name: slideInUp;
    -webkit-animation-duration: .3s;
    animation-duration: .3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    }
    @-webkit-keyframes slideInUp {
    0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    visibility: visible;
    }
    100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    }
    }
    @keyframes slideInUp {
    0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    visibility: visible;
    }
    100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    }
    }
.slideInUpSearch {
    position: fixed;
    z-index: 10;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    height: 350px;
    display: flex;
    justify-content: center;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    margin: 0 auto;
    padding: 15px;
    -webkit-animation-name: slideInUpSearch;
    animation-name: slideInUpSearch;
    -webkit-animation-duration: .3s;
    animation-duration: .3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
@-webkit-keyframes slideInUpSearch {
    0% {
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
        visibility: visible;
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}
@keyframes slideInUpSearch {
    0% {
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
        visibility: visible;
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

.rootContainer {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.Content {
    flex: 1;
    margin-top: 36px; /* Height of the header */
    margin-bottom: 48px; /* Height of the bottom bar */
    position: relative;
    overflow: hidden;
}

.swiperContainer {
    height: 100% !important;
    width: 100%;
}

.swiperContainer :global(.swiper-slide) {
    height: 100% !important;
    overflow: hidden;
}

.videoCard_player {
    height: 100%;
    width: 100%;
    position: relative;
}

.homeVideoContent {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media screen and (max-width: 500px) {
    .videoDescBlock {
        bottom: 110px;
    }
}