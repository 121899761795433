@import url("../../fonts/fonts.css");
.main{
    /* background-color: #F3BB03   ; */
    padding-top: 3rem;
    font-family: poppins_regular !important;
    height: 100%;
}
.logoText{
    font-size: 60px;
    font-family: outfitRegular !important;
  }
.accessPopup{
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    max-width: 300px;
    font-size: 18px;
    background: #353931;
    text-align: center;
    font-family: 'RifficBold';
    top: 30%;
    z-index: 2;
    padding: 15px;
    border-radius: 15px;
    box-shadow: 0px 0px 5px 5px #fff7e4;
}
.accessPopupWh1{
    font-size: 18px;
    padding: 15px;
    letter-spacing: 1.2px;
    line-height: 1.3;
}
.accessPopupWbtn1{
    padding: 8px;
    border-radius: 15px;
    font-size: 20px;
    letter-spacing: 1.1px;
    border: none;
    box-shadow: 0px 4px 0px 0px #919180;
    transition: .3s ease;
}
.accessPopupWbtn1:active{
    transform: translateY(4px);
    box-shadow: none;
    color: white;
    background-color: var(--bs-body-color);
}
.lds_ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds_ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: lds_ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }
  .lds_ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds_ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds_ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds_ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
.banner_section{
    height: 200px;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: end;
    /* padding-top: 1.2rem; */
}
.mt124px{
    margin-top: 124px;
}
.banner{
    background: url('../../images/v1/login/wave.png');
    background-repeat: repeat;
    background-size: auto;
    background-size: contain;
    width: 250px;
    /* height: 297px; */
    position: absolute;
    opacity: 0.7;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    background-repeat: no-repeat;
}
.title{
    align-self: center;
    font-size: 5rem;
    z-index: 1;
    color: var(--white);
    font-family: "rifficBold";
    margin-bottom: 15px;
}
.fs6rem{
    font-size: 6rem;
}
.subtitle{

}
.subtitle_H{
    align-self: center;
    font-size: 1rem;
    /* z-index: 1; */
    color: var(--black);
    font-family: "rifficBold";
}
.fs3rem{
    font-size: 1.3rem;
}
.games_banner{
    height: 124px;
    width: 100%;
}
.games_banner > div{
padding: 3px;
flex-basis: 1;
}
.games_banner img{
    width: 70px;
}

.loginFormTitle{
    font-size: 16px;
    color: #252525;
    align-self: center;
    font-family: poppins_regular !important;
}

.tagline {
    color: #252525;
    font-weight: bold;
    margin-top: 1.2em;
    margin-bottom: .5em;
    font-size: 24px;
    line-height: 1.3;
    font-family: poppins_regular !important;
}

.form_input{
    width: 75vw;
    min-width: 300px;
    border-radius: 10px;
    border: 1px solid #252525;
    background: transparent;
    color: #252525;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 133%;
    padding-left: 25px !important;
    height: 60px;
    letter-spacing: 1.2px;
    transition: .5s ease;
}

.form_input:focus{
    outline: none;
    background-color: #FECF33;
    /* color: black; */
}

.otpText{
    font-family: poppins_regular !important;
    font-size: 12px;
    color: black;
    text-align: center;
}
.logoTopLeft {
   position: fixed;
   top: 20px;
   left: max(20px, calc((125% - 700px) / 2 - 80px));
   width: 75px;
}
.logoTopRight {
   position: fixed;
   top: 20px;
   right: max(20px, calc((125% - 700px) / 2 - 70px));
   width: 65px;
}
.logoBottomRight {
   position: fixed;
   bottom: 20px;
   right: max(20px, calc((140% - 750px) / 2 - 120px));
   width: 100px;
}
.OTPInput {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    border: 1px solid #252525;
    height: 100px;
    width: 75vw;
    min-width: 300px;
    margin: 0 auto;
    border-radius: 10px;
    padding: 0 15px 15px 15px !important;
    transition: .5s ease;
}

.otpButton{
    width: 75vw;
    /* width: auto; */
    min-width: 200px;
    border-radius: 25px;
    padding: 5px;
    text-align: center;
    background: #252525;
    border: none;
    color: #FFCA0A;
    font-size: 24px;
    box-shadow: -1px 11px 29px -9px rgba(0,0,0,1);
    font-family: "outfitRegular";
    transition: .5s ease;
}
.otpButton:hover, .otpButton:active{
    background-color: #3d3c3a !important;
    color: #FFCA0A !important;
    transform: translateY(4px);
    box-shadow: 0 5px #666;
    border: 1px solid #3d3c3a;
}

.consent{
    font-size: 12px;
    font-family: poppins_regular !important;
    text-align: center;
    margin-bottom: 0;
    color: black;
}

.pinMessage{
    margin: 0;
    color: red;
    font-family: poppins_regular !important;
    text-align: center;
    padding: 5px 0;
}

.otpFld {
    font-family: poppins_regular !important;
    font-size: 30px;
}
.logoImg {
    width: 85vw;
    z-index: -1;
    max-width: 450px;
    margin-top: 2em
}

/* On screens that are 600px wide or less, make the columns stack on top of each other instead of next to each other */
@media screen and (max-width: 400px) {
    .banner_section {
        height: 165px;
    }
    .banner{
        width: 200px;
    }
    .title{
        font-size: 4rem;   
    }
    .games_banner img{
        width: 60px;
    }
    .formSection{
        margin-top: 15px;
    }
    
    /* .loginFormTitle,.otpText{
        font-size: 16px;
    } */
    .form_input{
        padding: 10px;
    }
    .pinMessage{
        font-size: 13px;
    }
  }

  @media screen and (max-width: 355px) {
    .games_banner img{
        width: 50px;
    }
    .consent{
        font-size:11px;
    }
  }

  @media screen and (min-width: 512px) {
    .form_input,.otpButton,.OTPInput{
        width: 55vw;
        max-width: 484px;
    }
  }

  @media screen and (max-width: 450px) {
    .main {
        height: 100%;
    }
    .logoTopLeft {
        width: 60px;
     }
     .logoTopRight {
        width: 55px;
     }
     .logoBottomRight {
        width: 70px;
     }
     .tagline {
        font-weight: bold;
        margin-top: 1.2em;
        margin-bottom: .3em;
        font-size: 21px;
        line-height: 1;
     }
     .logoImg {
        width: 65vw;
        max-width: 450px;
        margin-top: 1.5em
    }
    .form_input {
        width: 50vw;
        font-size: 18px;
        height: 50px;
        line-height: 1.2;
    }
   .otpButton {
    font-size: 21px;
    margin-bottom: 3px !important;
   }
   .consent {
    margin-bottom: 5px !important;
   }
   .otpFld {
    font-size: 20px;
   }  
   .OTPInput {
    height: 85px;
   }
   .form_input,.OTPInput{
        width: 55vw;
        max-width: 434px;
    }
  }

  @media screen and (max-width: 365px) {
    .logoTopLeft {
        width: 50px;
     }
     .logoTopRight {
        width: 45px;
     }
     .tagline {
        font-size: 20px;
     }
     .logoImg {
        width: 53vw;
    }
    .form_input {
        height: 45px;
    }
   .otpButton {
    font-size: 20px;
    margin-bottom: 3px !important;
   }
   .consent {
    margin-bottom: 5px !important;
   }
   .otpFld {
    font-size: 20px;
   }  
   .OTPInput {
    height: 85px;
   }
  }

  @media screen and (max-width: 320px) {
   .form_input,.OTPInput,.loginFormTitle{
        min-width: 200px;
        width: 85vw;
        max-width: 290px !important;
        font-size: 14px;
    }
    .otpButton {
        font-size: 17px;
    }
    .otpFld {
        font-size: 17px;
    }  
  }

  @media screen and (max-width: 320px) and (max-height: 600px) {
    .main{
        padding-top: 20px;
    }
    .logoTopLeft {
        width: 40px;
     }
     .logoTopRight {
        width: 40px;
     }
     .tagline {
        font-size: 18px;
     }
     .logoImg {
        width: 45vw;
    }
    .form_input {
        height: 43px;
    }
   .otpButton {
    font-size: 16px;
    margin-bottom: 1px !important;
   }
   .consent {
    margin-bottom: 1px !important;
   }
   .otpFld {
    font-size: 16px;
   }  
   .OTPInput {
    height: 70px;
   }
   .tagline {
    font-size: 16px;
   }
  }

  @media screen and (min-width: 1100px) {
    .logoTopLeft {
        left: max(20px, calc((115% - 700px) / 2 - 80px));
     }
     .logoTopRight {
        right: max(20px, calc((115% - 700px) / 2 - 70px));
     }
     .logoBottomRight {
        right: max(20px, calc((125% - 750px) / 2 - 120px));
     }
  }


