.sidebarBottomContainer {
    position: fixed;
    /*position:absolute;*/
    right: 10px;
    bottom: 90px;
    z-index: 2;
}
/* .sidebarBottomContainer div {
    margin-bottom: 10px;
} */
.sidebarBottomRightContainer{
    position: fixed;
    left: 10px;
    bottom: 60px;
}
.sidebarBtnHeart{
    padding-bottom: 15px;
    position: relative;
}
.sidebarBtnSearch{
    margin-bottom: 15px !important;
}
#sidebarSvg{
    width: 35px;
    height: 35px;
  }
.likeVideo svg path,.likeVideo  svg{
    fill : #bd0d0f;
}
.sidebarLink{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 55px;
    text-decoration: none;
}

.sidebarLinkBg{
    background: #41414199;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
}
.sidebarLinkText{
    font-size: 11px;
    margin-top: 10px;
    text-shadow: 0px 4px 5px #000, 0 0 5px #000;
}

.heartWhite{
    position: absolute;
    top: -3px;
    left: -3px;
    right: 0px;
    bottom: 0px;
    z-index: -1;
    width: 42px !important;
    height: 42px !important;
}

.svgSize{
    width: 18px;
    /* background: #05050526; */
    border-radius: 5px;
    /* box-shadow: 0 0 0px 5px #05050526; */
}